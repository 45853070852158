.button28 {
  appearance: none;
  background-color: transparent;
  border: 0px solid #1A1A1A;
  border-radius: 12px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  width: 50px;
  height: 50px;
  outline: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button28:disabled {
  pointer-events: none;
}

.button28:hover {
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button28:active {
  box-shadow: none;
  transform: translateY(0);
}
