#loader4 span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #138105;
  margin: 5px 5px;
  opacity: 0;
}

#loader4 span:nth-child(1) {
  animation: opacitychange 0.3s ease-in-out infinite;
}
#loader4 span:nth-child(2) {
  animation: opacitychange 0.3s ease-in-out 0.1s infinite;
}
#loader4 span:nth-child(3) {
  animation: opacitychange 0.3s ease-in-out 0.2s infinite;
}


@-moz-keyframes opacitychange {
  0%, 100% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}
@-webkit-keyframes opacitychange {
  0%, 100% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}
@-o-keyframes opacitychange {
  0%, 100% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}
@keyframes opacitychange {
  0%, 100% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}
